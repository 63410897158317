import React from "react";
import {useStaticQuery, graphql} from "gatsby";
import useLang from "../../contexts/Language/LanguageContext";
import { GatsbyImage } from 'gatsby-plugin-image';
import {link} from "./Partners.module.scss";

const sortFn = (a, b) => {
    return a.replace("...", "").localeCompare(b.replace("...", ""))
}

export default function Partners() {
    const lang = useLang();
    const partners = useStaticQuery(graphql`
    query {
        allContentfulPartners {
          edges {
            node {
              name
              nameen
              link
              logo {
                title
                description
                gatsbyImageData
              }
            }
          }
        }
      }
    `);

    return (
        <>
            <h1>{lang === "mne" ? "Partneri" : "Partners"}</h1>
            {partners.allContentfulPartners.edges
                .sort((ed1, ed2) => sortFn(ed1.node.name, ed2.node.name))
                .map(edge => {
                return (
                    <div key={edge.node.link} style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                        <GatsbyImage style={{maxWidth: "100px"}} image={edge.node.logo.gatsbyImageData} alt={edge.node.logo.title} />
                        <a className={link} href={edge.node.link} target="_blank" rel="noreferrer">{lang === "mne" ? edge.node.name : edge.node.nameen}</a>
                    </div>
                )
            })}
        </>
    )
}